<template lang="pug">
.form-modal(v-if="showFormManager.previewMissing")
  .content(style="min-height: fit-content; width: 420px")
    .header.om-bottom-divider.p-3
      .header-inner.w-100
        .col-12.header-content
          .d-flex.align-items-center
            .mb-0.font-weight-bold(style="font-size: 18px") {{ $t('previewError.title') }}
            om-button.ml-auto.p-1(
              icon="times"
              iconSize="1.715em"
              ghost
              iconOnly
              style="color: #b9bec6"
              @click="hideModal"
            )
    .d-flex.flex-column.body(style="border-bottom: 1px solid #edeff1")
      .d-flex.align-items-center(style="margin: 25px 40px")
        .mb-0(style="font-size: 15px; line-height: 24px") {{ $t('previewError.missing.content') }}
    .d-flex.flex-row.justify-content-end.footer
      .d-flex.justify-content-end
        om-button.mr-3(ghost @click="openWebSite()") {{ $t('previewError.openSite') }}
        om-button(secondary @click="hideModal()") {{ $t('close') }}
</template>
<script>
  import { mapMutations, mapState } from 'vuex';
  import { track } from '@/services/xray';
  import { getContextProperties } from '@/services/userInteractionTracker/properties';

  export default {
    computed: {
      ...mapState(['showFormManager', 'formModalMetaData']),
    },
    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      hideModal() {
        track('editor_click', {
          location: 'preview-modal',
          setting: 'missing-last-request-date',
          change: 'close',
          ...getContextProperties(),
        });
        this.changeFormManagerVisibility({ hide: 'previewMissing' });
      },
      openWebSite() {
        track('editor_click', {
          location: 'preview-modal',
          setting: 'missing-last-request-date',
          change: 'open-anyway',
          ...getContextProperties(),
        });
        window.open(this.formModalMetaData.url, '_blank');
      },
    },
  };
</script>
