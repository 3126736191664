<template lang="pug">
.sidebar-device-selector-backdrop(v-if="deviceSelectorList.show" @click.stop="onBackdropClick")
  .sidebar-device-selector-list(:style="listStyle")
    .sidebar-device-selector-list-item(
      v-for="device in correctDeviceList"
      @click.prevent="changePreview(device)"
      @mouseover="onEnter(device)"
      @mouseleave="onLeave(device)"
    )
      component(
        :is="`${device}-svg`"
        :active="hovered === device || device === currentDevice"
        :width="16"
        :height="16"
      )
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  const DEVICES = { MOBILE: 'mobile', DESKTOP: 'desktop' };
  export default {
    name: 'DeviceSelectorList',
    data: () => ({ DEVICES, hovered: null }),
    computed: {
      ...mapState(['mobilePreview', 'deviceSelectorList']),
      wrapperStyle() {
        return { float: this.float };
      },
      listStyle() {
        const { top, left } = this.deviceSelectorList.position;
        return { top: `${top - 10}px`, left: `${left - 8}px` };
      },
      currentDevice() {
        return this.mobilePreview ? DEVICES.MOBILE : DEVICES.DESKTOP;
      },
      correctDeviceList() {
        const base = [DEVICES.MOBILE, DEVICES.DESKTOP];
        return this.mobilePreview ? base : base.reverse();
      },
    },
    watch: {
      mobilePreview() {
        this.deviceSelectorList.show = false;
        this.hovered = null;
      },
    },
    methods: {
      ...mapActions(['setEditorPreviewMode']),
      changePreview(device) {
        this.deviceSelectorList.show = false;
        this.setEditorPreviewMode({ mode: device, reset: false });
      },
      onEnter(device) {
        this.hovered = device;
      },
      onLeave() {
        this.hovered = null;
      },
      onBackdropClick() {
        this.deviceSelectorList.show = false;
      },
    },
  };
</script>
