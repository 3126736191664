<template lang="pug">
.form-modal(v-if="showFormManager.couponCreate" @mousedown="hideModal")
  .content(style="min-height: fit-content; width: 26.25rem" @mousedown.stop="")
    .header.om-bottom-divider.p-3
      .header-inner.w-100
        .col-12.header-content
          .d-flex.align-items-center
            .mb-0.font-weight-bold(style="font-size: 18px") {{ $t('discountPane.fixed.createDiscountCode') }}
            om-button.ml-auto.p-1(
              icon="times"
              iconSize="1.715em"
              ghost
              iconOnly
              style="color: #b9bec6"
              @click="hideModal"
            )
    .d-flex.flex-column.body
      .shopify-create-error.mb-5(v-if="couponCreateError")
        UilExclamationTriangle
        .error-text(v-html="createErrorMessage")
      .coupon-code-title-container.mb-3
        om-tooltip(transition="fade")
          span(v-html="$t('discountPane.fixed.editInEditor')")
          template(slot="trigger")
            .coupon-code-title(:class="{ 'processing-create': processingCouponCreate }") {{ createDiscountProps.couponCode }}
      .pt-3.pb-6.coupon-code-settings(
        v-if="!processingCouponCreate && couponCreateError !== couponCreateErrors.NON_RETRYABLE"
      )
        .blocks
          .small-block(
            v-for="type in couponTypes"
            :key="type.key"
            :class="{ 'active-block': coupon.type === type.value }"
            @click="toggleType(type.value)"
          )
            .small-block-icon(:class="{ [type.key]: true }")
              component(:is="type.svg")
            .small-block-title
              span {{ $t(`discountPane.automatic.${type.key}`) }}
        template(v-if="coupon.type === 'PERCENTAGE'")
          .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0.coupon-label {{ $t('discountPane.automatic.percentageValue') }}
          .percentage-input-wrapper
            om-input#percentage-input(
              v-model.number="coupon.percentageValue"
              type="number"
              placeholder="e.g. 10"
            )
              template(#error v-if="validationErrors.percentage")
                span {{ validationErrors.percentageMsg }}
        template(v-else)
          .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0.coupon-label {{ $t('discountPane.automatic.fixedValue', { currency: this.createDiscountProps.currency }) }}
            .amount-input-wrapper
              om-input#amount-input(
                v-model.number="coupon.fixedValue"
                type="number"
                placeholder="e.g. 10"
              )
                template(#error v-if="validationErrors.fixed")
                  span {{ validationErrors.fixedMsg }}
        om-switches.expiration-switch(
          label="discountPane.fixed.expiration"
          property=""
          v-model="expiration"
          @click.native="toggleExpiration"
        )
        template(v-if="expiration")
          .sidebar-input-wrapper-label.date-time-label.pb-0.pt-0.coupon-label {{ $t('discountPane.fixed.fixedExpiration') }}
          .date-time-holder.d-flex.align-items-center
            .sidebar-input-wrapper
              v-date-picker.form-control.d-flex.brand-date-picker(
                mode="single"
                :min-date="new Date()"
                :formats="{ title: 'MMMM YYYY', weekdays: 'W', navMonths: 'MMM', input: [dateFormat], dayPopover: shortDateFormat }"
                v-model="expiresDate"
                :class="{ 'is-invalid': validationErrors.expiresDate }"
              )
                template(v-slot="{ inputValue, inputEvents }")
                  input(:value="inputValue" v-on="inputEvents")
                  .brand-date-picker-icon
                    fa-icon(variant="fa-calendar-o" size="1.25")
            om-dropdown-input.time-input(
              label=""
              v-model="expiresHour"
              :i18n="false"
              :items="hours"
              :class="{ 'validation-failed': validationErrors.expiresDate }"
            )
            span.d-flex.align-items-center.colon :
            om-dropdown-input.time-input(
              label=""
              v-model="expiresMinute"
              :i18n="false"
              :items="minutes"
              :class="{ 'validation-failed': validationErrors.expiresDate }"
            )
          .validation-failed-msg(v-if="validationErrors.expiresDate") {{ $t(validationErrors.expiryInvalidMsg) }}
          .sub-title.mb-3(
            v-html="$t('discountPane.automatic.fixedSubTitle', { offset: getDiffToGMT() })"
          )
        .additional-settings-info
          uil-info-circle
          .additional-settings-info-text(
            v-html="$t('discountPane.fixed.couponAdditionalSettings', { link: this.createDiscountProps.shopifyAdminLink })"
          )
      .loading-coupon(v-if="processingCouponCreate")
        img(:src="require('@/assets/editor/svg/loader.svg')")
    .d-flex.flex-row.justify-content-end.footer
      .d-flex.justify-content-end
      om-button.mr-3.cancel-btn(ghost @click="hideModal") {{ $t('cancel') }}
      om-button(
        primary
        @click="createCoupon"
        :disabled="processingCouponCreate || couponCreateError === couponCreateErrors.NON_RETRYABLE"
      ) {{ $t('create') }}
</template>
<script>
  import moment from 'moment';
  import { mapState, mapMutations } from 'vuex';
  import PercentageSVGVue from '@/editor/components/svg/PercentageSVG.vue';
  import MoneyFixedSVGVue from '@/editor/components/svg/MoneyFixedSVG.vue';
  import dateFormat from '@/mixins/dateFormat';
  import DateTimepicker from '@/components/DateTimepicker';
  import { UilInfoCircle, UilExclamationTriangle } from '@iconscout/vue-unicons';
  import CREATE_COUPON from '@/graphql/CreateCoupon.gql';
  import { track } from '../../../services/xray';

  export default {
    components: { DateTimepicker, UilInfoCircle, UilExclamationTriangle },
    mixins: [dateFormat],
    data() {
      const now = new Date().toISOString();
      return {
        couponTypes: [
          { key: 'percentage', value: 'PERCENTAGE', svg: PercentageSVGVue },
          { key: 'fixed', value: 'FIXED', svg: MoneyFixedSVGVue },
        ],
        hours: Array.from({ length: 24 }, (_, i) => ({ value: i, key: this.timeUnitFormat(i) })),
        minutes: Array.from({ length: 60 }, (_, i) => ({ value: i, key: this.timeUnitFormat(i) })),
        expiration: false,
        now,
        coupon: {
          type: 'PERCENTAGE',
          fixedValue: null,
          percentageValue: null,
          expirationTime: moment().add(7, 'days').endOf('day').toISOString(),
        },
        processingCouponCreate: false,
        couponCreateErrors: {
          NON_RETRYABLE: 'non-retryable',
          RETRYABLE: 'retryable',
        },
        couponCreateError: null,
        validationErrors: {
          percentage: false,
          percentageMsg: '',
          fixed: false,
          fixedMsg: '',
          expiresDate: false,
          expiryInvalidMsg: this.$t('fieldMustBeInFuture'),
        },
      };
    },
    computed: {
      ...mapState(['showFormManager', 'createDiscountProps', 'campaign']),
      expiresDate: {
        get() {
          return new Date(this.coupon.expirationTime);
        },
        set(v) {
          const newDate = moment(v);
          const expirationTime = moment(this.coupon.expirationTime);
          expirationTime.year(newDate.year());
          expirationTime.month(newDate.month());
          expirationTime.date(newDate.date());
          this.setExpirationTime(expirationTime);
        },
      },
      expiresHour: {
        get() {
          return moment(this.coupon.expirationTime).hour();
        },
        set(v) {
          const expirationTime = moment(this.coupon.expirationTime);
          expirationTime.hour(v);
          this.setExpirationTime(expirationTime);
        },
      },
      expiresMinute: {
        get() {
          return moment(this.coupon.expirationTime).minute();
        },
        set(v) {
          const expirationTime = moment(this.coupon.expirationTime);
          expirationTime.minute(v);
          this.setExpirationTime(expirationTime);
        },
      },
      createErrorMessage() {
        if (this.couponCreateError === this.couponCreateErrors.RETRYABLE) {
          return this.$t('discountPane.fixed.couponCreateRetry');
        }
        return this.$t('discountPane.fixed.couponCreateError');
      },
    },
    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      hideModal() {
        this.changeFormManagerVisibility({ hide: 'couponCreate', createDiscountProps: {} });
        this.resetCouponData();
        this.resetValidationErrors();
        this.couponCreateError = null;
      },
      isFixedValueInvalid() {
        const { type, fixedValue } = this.coupon;
        if (type === 'FIXED') {
          if (fixedValue === null || fixedValue === '') {
            this.validationErrors.fixedMsg = this.$t('fieldRequired');
            return true;
          }
          if (fixedValue <= 0) {
            this.validationErrors.fixedMsg = this.$t('fieldMustBePositive');
            return true;
          }
          return false;
        }
        return false;
      },
      isPercentageValueInvalid() {
        const { type, percentageValue } = this.coupon;
        if (type === 'PERCENTAGE') {
          if (percentageValue === null || percentageValue === '') {
            this.validationErrors.percentageMsg = this.$t('fieldRequired');
            return true;
          }
          if (percentageValue <= 0 || percentageValue >= 100) {
            this.validationErrors.percentageMsg = this.$t('fieldNotPercentage');
            return true;
          }
          return false;
        }
        return false;
      },
      isExpiresDateInValid() {
        const { expirationTime } = this.coupon;
        if (this.expiration && new Date(expirationTime) <= new Date()) {
          return true;
        }
        return false;
      },
      validateCreateData() {
        if (this.isFixedValueInvalid()) {
          this.validationErrors.fixed = true;
          return false;
        }
        if (this.isPercentageValueInvalid()) {
          this.validationErrors.percentage = true;
          return false;
        }
        if (this.isExpiresDateInValid()) {
          this.validationErrors.expiresDate = true;
          return false;
        }
        return true;
      },
      processErrors(errors) {
        if (Array.isArray(errors)) {
          const hasNonRetryableError = errors.find(
            (error) => error.code !== '429' || error.code !== '500',
          );
          if (hasNonRetryableError) {
            this.couponCreateError = this.couponCreateErrors.NON_RETRYABLE;
          } else {
            this.couponCreateError = this.couponCreateErrors.RETRYABLE;
          }
        } else {
          this.couponCreateError = this.couponCreateErrors.NON_RETRYABLE;
        }
      },
      async createCoupon() {
        if (this.validateCreateData()) {
          this.resetValidationErrors();
          this.couponCreateError = null;
          this.processingCouponCreate = true;

          try {
            const {
              data: { couponData },
              errors,
            } = await this.$apollo.query({
              query: CREATE_COUPON,
              variables: {
                input: {
                  code: this.createDiscountProps.couponCode,
                  starts: this.now,
                  ends: this.expiration ? this.coupon.expirationTime : null,
                  type: this.coupon.type,
                  value:
                    this.coupon.type === 'FIXED'
                      ? this.coupon.fixedValue
                      : this.coupon.percentageValue / 100,
                },
                shopId: this.campaign.domain,
              },
            });

            this.processingCouponCreate = false;
            if (couponData) {
              this.$bus.$emit('couponCreated', {
                couponData: {
                  ...couponData,
                  type: 'DiscountCodeBasic',
                },
              });
              track('couponCreate');
              this.hideModal();
            }
            if (errors?.length) {
              this.processErrors(errors);
            }
          } catch (e) {
            this.processingCouponCreate = false;
            this.processErrors(e);
          }
        }
      },
      toggleType(type) {
        this.coupon.type = type;
      },
      toggleExpiration() {
        this.expiration = !this.expiration;
      },
      setExpirationTime(time) {
        this.coupon.expirationTime = time.toISOString();
      },
      resetValidationErrors() {
        this.validationErrors = {
          percentage: false,
          percentageMsg: '',
          fixed: false,
          fixedMsg: '',
          expiresDate: false,
          expiryInvalidMsg: this.$t('fieldMustBeInFuture'),
        };
      },
      resetCouponData() {
        this.coupon = {
          type: 'PERCENTAGE',
          fixedValue: null,
          percentageValue: null,
          expirationTime: moment().add(7, 'days').endOf('day').toISOString(),
        };
      },
    },
  };
</script>
<style lang="sass">
  #percentage-input
    padding-right: 2.3rem
    text-align: left!important
</style>
<style lang="sass" scoped>
  @import '../../../sass/variables/_colors'
  .body
    padding: 24px 40px

  .blocks
    display: flex
    margin-bottom: 20px
    .small-block
      height: 60px
      flex: 1
      border-radius: 4px
      border: 1px solid $om-gray-400
      cursor: pointer
      &:last-of-type
        margin-left: 8px
      &-icon
        margin-top: 12px
        margin-bottom: 8px
        display: flex
        justify-content: center
      &-title
        display: flex
        justify-content: center
        color: $om-gray-700
        font-size: 0.625rem
        line-height: 1rem
    .active-block
      height: 60px
      width: 120px
      border-radius: 4px
      border: 1px solid $om-orange-500

  .percentage-input-wrapper ::v-deep input::-webkit-outer-spin-button,
  .percentage-input-wrapper ::v-deep input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

  .amount-input-wrapper ::v-deep input::-webkit-outer-spin-button,
  .amount-input-wrapper ::v-deep input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

  .percentage-input-wrapper ::v-deep input[type=number]
    -moz-appearance: textfield

  .percentage-input-wrapper::after
    position: absolute
    top: 12px
    right: 0.8em
    transition: all .05s ease-in-out

  #percentage-input
    padding-right: 2.5rem
    text-align: left!important
  .percentage-input-wrapper::after
    content: url(~@/assets/editor/svg/Percentage.svg)

  .percentage-input-wrapper
    position: relative

    ::v-deep .form-control.is-invalid
      background-position: right calc(2.5rem) center

  .sidebar-input-wrapper-label
    font-size: 0.75rem

  .coupon-code-title-container
    border: 1px dashed $om-gray-400
    border-radius: 8px
    padding: 20px

    ::v-deep .ds-tooltip span
      white-space: nowrap

  .coupon-code-title
    font-size: 1.125rem
    font-weight: 700
    color: $om-gray-700

    &.processing-create
      color: $om-gray-500

  .coupon-code-settings
    border-top: 1px solid $om-gray-300

  .expiration-switch
    flex-direction: row !important
    margin-top: 20px

    ::v-deep .sidebar-title-tooltip-container
      padding-left: 0

  .expiration-switch ::v-deep label
    color: $om-gray-700
    font-weight: 500
    margin-bottom: 0
    font-size: 0.75rem !important

  .date-time-label
    margin-top: 20px

  .date-time-holder
    .sidebar-input-wrapper
      margin-right: 5px

      &:first-child
        margin-right: 3px
        width: 166px

      &:last-child
        margin-right: 0

    ::v-deep .brand-date-picker
      padding: 0

      input
        height: 38px
        width: 164px
        padding-left: 5px
        border: none

      .brand-date-picker-icon
        position: absolute
        right: 5px
        top: 10px

    .validation-failed ::v-deep select
      background: rgba(255, 0, 0, 0.1) !important
      border-color: #dc3545 !important
      option
        background: white

  .time-input
    ::v-deep .epic-simple-input
      width: 77px !important
      margin-left: 5px

      .select-arrow
        transform: rotate(0deg) !important
        top: 10px !important

  .sub-title
    margin-top: 4px
    font-size: 0.75rem
    line-height: 1rem
    color: $om-gray-600

  .additional-settings-info
    display: flex
    align-items: center
    margin-top: 20px

    svg
      height: 24px
      width: 24px
      margin-right: 8px
      align-self: flex-start
      flex-shrink: 0
      color: $om-gray-700

    .additional-settings-info-text
      font-size: 0.75rem
      color: $om-gray-700

      ::v-deep a
        color: $om-gray-700
        text-decoration: underline

  .footer
    padding: 0 40px 20px 40px !important

  .shopify-create-error
    border-radius: 3px
    border-width: 1px
    border-style: solid
    padding: 12px 15px
    display: flex
    border-color: #be1820

    svg
      align-self: center
      margin-right: 9px
      font-size: 1.25rem
      color: #be1820
      height: 24px
      width: 24px

    .error-text
      font-size: 0.625rem
      color: #505763
      margin-bottom: 0

      ::v-deep a
        font-size: 0.625rem
        color: #505763
        text-decoration: underline

  .validation-failed-msg
    color: #f88
    font-size: .625rem
    margin: .4375em 0
    padding: 0.5em
    background: lighten(#f88, 18%)
    border-radius: 2px
    border: 1px solid #f88

  .header
    height: 64px

    .header-inner
      ::v-deep svg
        margin-right: -10px

  .coupon-label
    color: $om-gray-700
    line-height: 24px

    &.processing-create
      color: $om-gray-500

  .colon
    margin-left: 5px

  .loading-coupon
    height: 160px
    margin-bottom: 68px
    position: relative
    display: flex
    align-items: center
    justify-content: center
    z-index: 10
    font: normal normal normal 0.875rem/1 FontAwesome
    -animation: spin .7s infinite linear
    -moz-animation: spin .7s infinite linear
    -ms-animation: spin .7s infinite linear
    -webkit-animation: spin2 .7s infinite linear

  .cancel-btn
    color: $om-gray-700
</style>
