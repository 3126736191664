import ALL_IMAGES from '@/graphql/AllImages.gql';
import ALL_TEMPLATE_IMAGES from '@/graphql/AllTemplateImages.gql';
import { getNameFromURL } from '@om/template-properties/src/imageReplace';

const IMAGE_MAX_AREA = 10000;
export default {
  apollo: {
    images: {
      query: ALL_IMAGES,
      result({ data: { images } }) {
        return images?.filter?.(({ width, height }) => width * height < IMAGE_MAX_AREA) ?? [];
      },
    },
    allTemplateImages: {
      query: ALL_TEMPLATE_IMAGES,
      result({ data: { allTemplateImages } }) {
        return (
          allTemplateImages?.filter?.(({ width, height }) => width * height < IMAGE_MAX_AREA) ?? []
        );
      },
    },
  },
  methods: {
    getNameFromURL(url) {
      return getNameFromURL(url);
    },
  },
};
