<template lang="pug">
om-input#customThemeName.mb-5(
  v-model.trim="themeKitName"
  type="text"
  :error="hasError"
  :errorText="errorText"
  :disabled="disabled"
)
</template>
<script>
  import { mapGetters } from 'vuex';
  import { debounce } from 'lodash-es';
  import GET_THEME_COUNT from '@/graphql/GetCustomThemeCount.gql';

  export default {
    props: {
      disabled: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        oldName: '',
        themeKitName: '',
        nameAlreadyUsed: false,
        nameTooShort: false,
        nameTooLong: false,
      };
    },
    computed: {
      ...mapGetters(['getCustomThemeKitValues', 'template']),
      hasError() {
        return this.nameTooShort || this.nameTooLong || this.nameAlreadyUsed;
      },
      errorText() {
        if (this.nameTooShort) {
          return this.$t('customTheme.notifications.themeTooShort');
        }

        if (this.nameTooLong) {
          return this.$t('customTheme.notifications.themeTooLong');
        }

        return this.$t('customTheme.notifications.themeAlreadyExists');
      },
    },
    watch: {
      themeKitName: debounce(async function (name, oldName) {
        this.nameTooShort = false;
        this.nameTooLong = false;
        this.nameAlreadyUsed = false;

        this.$store.commit('setThemeKitName', name);
        if (this.oldName === name) {
          return;
        }

        if (name.length < 3) {
          this.nameTooShort = true;
          if (name.length < 1) {
            // don't let users completely delete name, make sure 1 char is always there
            this.$store.commit('setThemeKitName', oldName[0]);
          }
          return;
        }

        if (name.length > 30) {
          this.nameTooLong = true;
          return;
        }

        const { data } = await this.$apollo.query({
          query: GET_THEME_COUNT,
          variables: {
            name,
          },
        });
        const customThemeCount = data.customThemeCount;
        if (customThemeCount > 0) {
          this.nameAlreadyUsed = true;
        }
      }, 100),
      hasError(value) {
        this.$store.commit('setIsCustomThemeNameError', value);
      },
    },
    mounted() {
      this.themeKitName = this.getCustomThemeKitValues?.name;
      this.oldName = this.template.themeKit.name;
    },
  };
</script>
