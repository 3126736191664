<template lang="pug">
svg(width='40' height='27' viewBox='0 0 40 27' fill='none' xmlns='http://www.w3.org/2000/svg')
  rect(width='40' height='26' rx='4' transform='matrix(1 0 0 -1 0 26)' fill='white')
  path(d='M0 23.6667C0 25.3235 1.34315 26.6667 3 26.6667H21.3333V20.3333C21.3333 18.6765 19.9902 17.3333 18.3333 17.3333H0V23.6667Z' :fill='color')
  path(fill-rule='evenodd' clip-rule='evenodd' d='M36 1.33333H4C2.52724 1.33333 1.33333 2.52724 1.33333 4V22.6667C1.33333 24.1394 2.52724 25.3333 4 25.3333H36C37.4728 25.3333 38.6667 24.1394 38.6667 22.6667V4C38.6667 2.52724 37.4728 1.33333 36 1.33333ZM4 0C1.79086 0 0 1.79086 0 4V22.6667C0 24.8758 1.79086 26.6667 4 26.6667H36C38.2091 26.6667 40 24.8758 40 22.6667V4C40 1.79086 38.2091 0 36 0H4Z' :fill='color')
</template>

<script>
  export default {
    props: {
      color: {
        type: String,
      },
    },
  };
</script>
