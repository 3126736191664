<template lang="pug">
om-modal.custom-font-upload-modal-v2(
  name="custom-font-upload-v2"
  color="light"
  @beforeOpen="reset"
  width="450"
)
  template(slot="modal-header")
    .d-flex
      .font-weight-bold.align-self-center {{ $t('customFontUpload') }}
    .brand-modal-action-icon
      span.cursor-pointer(
        @click="$modal.hide('custom-font-upload-v2')"
        title="close"
        aria-hidden="true"
      ) X
  template(slot="modal-body")
    template(v-if="!selectedFile")
      upload-drop-zone(@change="processFile($event)")
      .brand-error(v-if="extensionMismatch") {{ $t(`customFontErrors.${errorKey}`) }}
      .alt-title
        .text-center.font-size-0--875.mb-5.mt-3 {{ $t('customFontExtensionsV2') }}
        .text-center.font-size-0--875.mt-5.mb-6 {{ $t('fontLicense') }}
      //-label(for="customFontName") {{ $t('name') }}
    .file-name-input.d-flex(v-if="selectedFile")
      .input-container.d-flex.flex-column
        input#customFontName.form-control.brand-input(
          type="text"
          :class="{ 'is-invalid': nameIsUsed || $v.fontName.$error }"
          :placeholder="$t('fontName')"
          v-model.trim="fontName"
          @click="errorKey = null"
        )
        .d-flex.brand-error(v-if="$v.fontName.$error && !$v.fontName.required") {{ $t('requiredField') }}
        .d-flex.brand-error(v-else-if="nameIsUsed") {{ $t(`customFontErrors.${errorKey}`) }}
      .button-area.d-flex.mb-5
        om-button.mr-3(ghost @click="$modal.hide('custom-font-upload-v2')") {{ $t('cancel') }}
        om-button(primary @click="upload" :class="{ loading: loading }") {{ $t('save') }}
    //- .brand-link.brand-link-secondary.text-center.mt-3(v-if="selectedFile" @click="reset") {{ $t('back') }}
</template>

<script>
  import { mapGetters } from 'vuex';
  import { restClient as axios } from '@/editor/axios';
  import { required } from 'vuelidate/lib/validators';
  import UploadDropZone from '@/components/UploadDropZone';
  import slugify from 'slugify';

  export default {
    components: { UploadDropZone },

    data() {
      return {
        fontName: '',
        selectedFile: null,
        loading: false,
        validExtensions: ['ttf', 'otf', 'woff', 'woff2'],
        hasFontAdded: false,
        uploadedFontData: null,
        errorKey: null,
        themeKitIndex: null,
        origin: undefined,
      };
    },

    validations: {
      fontName: {
        required,
      },
    },

    computed: {
      ...mapGetters(['installedFonts']),
      fileName() {
        return this.selectedFile ? this.selectedFile.name : '';
      },
      nOfFonts() {
        return this.installedFonts.length;
      },
      nameIsUsed() {
        return this.errorKey === 'nameIsUsedV2';
      },
      extensionMismatch() {
        return this.errorKey === 'extensionMismatchV2';
      },
    },
    watch: {
      nOfFonts(newValue, oldValue) {
        if (!this.uploadedFontData || newValue === oldValue) {
          return;
        }
        const fonts = this.installedFonts;
        fonts.forEach((font) => {
          if (font.key === this.uploadedFontData.value) {
            this.hasFontAdded = true;
          }
        });
      },
      hasFontAdded(newVal) {
        if (newVal) {
          this.$bus.$emit('uploadedBrandFont', {
            name: this.uploadedFontData.name,
            value: this.uploadedFontData.value,
          });
          this.hasFontAdded = false;
          this.uploadedFontData = null;
          this.$modal.hide('custom-font-upload-v2');
        }
      },
    },
    methods: {
      reset(e) {
        this.$v.$reset();
        this.fontName = '';
        this.selectedFile = null;
        this.loading = false;
        this.hasFontAdded = false;
        this.uploadedFontData = null;
        this.errorKey = null;
        this.origin = e.params?.origin || undefined;

        this.themeKitIndex = e.params?.themeKitIndex;
      },

      getExtensionFromStr(str) {
        return str.substring(str.lastIndexOf('.') + 1);
      },

      getFileNameFromPath(path) {
        return path.match(/[-_\w]+[.][\w]+$/i)[0];
      },

      isValidName(name) {
        let isValid = true;
        this.installedFonts.forEach((row) => {
          if (row.family === name) {
            isValid = false;
          }
          if (row.key === slugify(name)) {
            isValid = false;
          }
        });
        return isValid;
      },

      invalidNameAction() {
        const errorKey = 'nameIsUsedV2';
        this.errorKey = errorKey;
        this.$notify({
          type: 'error',
          title: this.$t(`customFontErrors.${errorKey}`),
        });
      },

      async processFile(file) {
        this.errorKey = null;
        const extension = this.getExtensionFromStr(file.name);
        if (this.validExtensions.includes(extension) === false) {
          const errorKey = 'extensionMismatchV2';
          this.errorKey = errorKey;
          this.$notify({
            type: 'error',
            title: this.$t(`customFontErrors.${errorKey}`),
          });
          return;
        }
        this.fontName = file.name.replace(`.${extension}`, '');
        this.selectedFile = file;
        if (this.isValidName(this.fontName) === false) {
          this.invalidNameAction();
        }
      },

      async upload() {
        this.$v.$touch();

        if (this.$v.$invalid || !this.selectedFile) {
          if (!this.selectedFile) {
            this.$notify({
              type: 'error',
              title: this.$t('customFontErrors.selectAFile'),
            });
          }
          return;
        }

        if (this.isValidName(this.fontName) === false) {
          this.invalidNameAction();
          return;
        }

        this.loading = true;

        const formData = new FormData();
        formData.append('name', this.fontName);
        formData.append('file', this.selectedFile);

        try {
          const { data } = await axios.post('upload/custom-font-v2', formData, {
            headers: {
              'content-type': 'multipart/form-data',
            },
          });

          this.$bus.$emit('getFonts');
          this.hasFontAdded = false;
          this.uploadedFontData = { name: data.name, value: data.value };

          const eventData = { ...this.uploadedFontData, origin: this.origin };
          if (this.themeKitIndex !== null) {
            eventData.themeKitIndex = this.themeKitIndex;
          }

          this.$bus.$emit('customFontAdded', eventData);
        } catch (e) {
          const errorKey = e.response.data.errorKey;
          this.errorKey = errorKey;
          this.$notify({
            type: 'error',
            title: this.$t(`customFontErrors.${errorKey}`),
          });
        }

        this.loading = false;
      },
    },
  };
</script>

<style lang="sass" scoped>
  .custom-font-upload-modal-v2
    ::v-deep.brand-modal-header
      padding-top: 0.3rem !important
      padding-bottom: 0 !important
      border-bottom: 1px solid #e7e7e7
      height: 4rem
      display: flex !important
    ::v-deep.brand-modal-body
      padding: 2rem 3.125rem 0 3.125rem
    ::v-deep.brand-modal-footer
      margin: unset !important
      padding: unset !important
    .brand-input-error
      top: 0.225rem
      left: -10.5rem
    .alt-title
      color: #8F97A4 !important
      display: flex
      flex-direction: column
      div:last-child
        width: 270px
        display: flex
        align-self: center
        margin-top: unset !important
    .file-name-input
      flex-flow: column
      .input-container
        margin-bottom: 44px
      .button-area
        align-self: end
    .brand-error
      font-size: .75rem
</style>
