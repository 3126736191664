const tinycolor = require('tinycolor2');

const isSameThemekit = (themeKit, originThemeKit) => {
  try {
    if (!themeKit?.fonts.every((font, index) => originThemeKit.fonts[index] === font)) {
      return false;
    }
    if (themeKit?.rounding !== originThemeKit.rounding) return false;

    const userMainColor = tinycolor(themeKit?.colors?.mainColor).toHexString();
    const ogMainColor = tinycolor(originThemeKit.colors.mainColor).toHexString();

    if (userMainColor !== ogMainColor) return false;

    const isSameSecondaryColors = themeKit?.colors?.secondaryColors.every((color, index) => {
      const userSecondaryColor = tinycolor(color).toHexString();
      const ogSecondaryColor = tinycolor(
        originThemeKit.colors.secondaryColors[index],
      ).toHexString();

      return userSecondaryColor === ogSecondaryColor;
    });

    if (!isSameSecondaryColors) return false;

    const themeColors = originThemeKit.colors.themeColors.map(({ themeColor }) =>
      tinycolor(themeColor).toHexString(),
    );
    const isSameThemeColors = themeKit?.colors?.themeColors.every(({ themeColor }, index) => {
      const userThemeColor = tinycolor(themeColor).toHexString();
      return themeColors[index] === userThemeColor;
    });

    if (!isSameThemeColors) return false;

    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

module.exports = isSameThemekit;
