<template lang="pug">
.sidebar.no-overflow.left.editor(
  :class="{ 'd-flex-1': true, 'template-editor-mode': isTemplateEditorMode && databaseId === 44 }"
  data-track-property="location:left-menu|setting:{event}"
)
  .left-sidebar.custom-width.br-1
    .d-flex-1.flex-column.mr-1.ml-1.mt-1.pointer.sidebar-button.position-relative(
      v-for="(button, index) in buttons"
      :key="button.components"
      @click="selectButton(index)"
      @mouseover="setHoverClass(index)"
      @mouseleave="removeHoverClass(index)"
      :id="button.id"
      v-if="button.shouldDisplay()"
      :data-track-property="getSidebarTrackProperty(index)"
    )
      .d-flex-1.justify-content-center.text-center.pt-4.mb-1
        .inner-div(:class="button.isActive ? 'left-button-selected' : 'not-selected'")
          component(:color="button.isActive ? '#ED5A29' :  button.isHovered ? '#8F97A4' : '#505763' " :is="button.components" :key="button.components")
      .justify-content-center.small-font-size.mb-2(:style="button.isActive ? 'color: #ED5A29; margin-top: -5px' : 'margin-top: -5px' " )
        span {{$t(button.text)}}
    .help-icon(v-if="getLocale === 'en'" @click="showHelp")
      UilQuestionCircle
      label.text.cursor-pointer {{ $t('help') }}
  .sidebar.no-overflow.left.d-flex-1(ref="closed" :class="{ 'brand-kit-wrapper': activeTab && activeTab.id === 'theme' }")
    transition(name="fade")
      sidebar-tab-wrapper(style="width:100%; overflow-y: auto;" defaultTab="step" v-show="!slideInProgress" :getTabs="false" ref="sidebarTabWrapper")
        template(#tabs)
        template(#content)
          sidebar-tab-content.sidebar-tab-content.brand-kit-colors(id="theme" v-if="isUniversal" data-track-property="location:brandkit")
            .sidebar-inner-box.pt-0
              .no-border-top-h3
                .brand-kit(:class="{'custom-theme': isThemeKit}")
                  template(v-if="isThemeKit")
                    .brand-heading
                      om-info-box#Theme(
                        v-if="showThemeInfoBox"
                        :title="$t('infoBox.themeTitle')"
                        :text="$t('infoBox.themeText')"
                        :learnMoreUrl="$t('infoBox.themeLearnMoreUrl')"
                      )
                      .d-flex.mb-3.align-items-center
                        .h3-elem.px-0.pb-0 {{ $t('editor.customTheme') }}
                      Custom-Theme-Name-Select(:disabled="!isCustomThemeChanged")
                      .mb-3(v-if="hasLogoDefinedInBaseTheme")
                        .h3-elem.p-0.mb-1 {{ $t('onboarding.customTheme.customize.labels.logo') }}
                        #brandkit-logo.d-flex.align-items-center
                          .brandkit-logo-preview-box.d-flex.align-items-center.justify-content-center.flex-grow-1.cursor-pointer(v-if="currentLogo" @click="uploadLogo")
                            img(:src="currentLogo")
                          om-button(
                            v-if="!currentLogo"
                            secondary
                            small
                            icon="upload"
                            block
                            @click="uploadLogo"
                          ) {{ $t('themeKit.uploadLogo') }}
                          om-button(v-else small iconOnly ghost icon="trash-alt" @click.stop="clearLogo")
                      SidebarColorPalette(ref="themeColors" data-track-property="component:brand-color")
                    .brand-heading
                      Theme-Font-Manager(data-track-property="component:custom-theme-font")
                    .brand-heading
                      .alt-title {{ $t('brandKitGroup.rounding') }}
                      Rounding-Manager(data-track-property="component:custom-theme-radius")
                  template(v-else)
                      SidebarColorPalette(ref="themeColors" data-track-property="component:brand-color")
          sidebar-tab-content.sidebar-tab-content(id="step" data-track-property="location:display")
            .sidebar-inner-box.pt-0
              .no-border-top-h3
                sidebar-accordion(v-if="!isUniversal" :title="$t('themeColors')" :canRemainOpen="true" ref="themeColors" :leftSidebar="true")
                  SidebarColorPalette
                sidebar-accordion(v-if="isTeaser" :title="$t('teaserPosition')" :canRemainOpen="true" ref="positions" :leftSidebar="true")
                  .sidebar-input-wrapper.flex-row-baseline
                    span
                      label {{$t('position')}}
                        DeviceSelector(:hasViewText="false")
                    span(v-if="hasTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER)" :style="desktopStyle()")
                      position-svg(v-if="!mobilePreview" property="selectedPage.data.position" bgFill="#dedede")
                      position-svg-mobile(v-else property="selectedPage.data.mobilePosition")
                    span(v-else style="width: 55%; height: 100%")
                      position-svg(property="globalStyle.tab.position" bgFill="#dedede")
                  .sidebar-input-wrapper(v-if="isV2Teaser")
                    om-margin(
                      v-if="!mobilePreview"
                      propsProperty=""
                      subPath="selectedPage.desktop.margin"
                      typeOfComponent="page"
                      :opened="true"
                      :embedded="false"
                      :showAccordion="false"
                      label="distanceFromTheEdge"
                      :limitedSides="true"
                    )
                    om-margin(
                      v-else
                      propsProperty=""
                      subPath="selectedPage.mobile.margin"
                      typeOfComponent="page"
                      :opened="true"
                      :embedded="false"
                      :showAccordion="false"
                      label="distanceFromTheEdge"
                      :limitedSides="true"
                    )
                sidebar-accordion(v-else :title="$t('position')" :canRemainOpen="true"  ref="positions" :leftSidebar="true")
                  global-display-box-beta
                    om-switches(v-if="showFitToScreen && !mobilePreview" label="100h" property="globalStyle.overlay.fitToScreen" typeOfComponent="global")
                    om-switches(v-if="showFitToScreen && isFitToScreen && !mobilePreview" label="fullHeightWithOverlay" property="globalStyle.overlay.withOverlay" typeOfComponent="global")
                    om-switches(v-if="showFitToScreen && mobilePreview" label="fullHeightOnMobile" property="globalStyle.overlay.fitToScreenOnMobile" typeOfComponent="global")
                    om-switches(v-if="isSidebar && mobilePreview && !isNano" label="withoutOverlayOnMobile" property="globalStyle.overlay.withoutOverlayOnMobile" typeOfComponent="global")
                    om-dropdown-input(v-if="(showFitToScreen && isFitToScreen && !mobilePreview) || (showFitToScreen && isFitToScreenOnMobile && mobilePreview)" label="contentPosition" property="globalStyle.overlay.alignItems" :items="alignItems" defaultValue="center" typeOfComponent="global")
                    om-dropdown-input(label="overflow" property="selectedPage.desktop.overflow" :items="overflowOptions" defaultValue="default" typeOfComponent="canvas")
                sidebar-accordion(:title="$t('transition')" v-if="!isTeaser && !isEmbedded" :canRemainOpen="true" ref="transition" :leftSidebar="true")
                  om-dropdown-input(v-if="!isEmbedded" label="type", property="globalStyle.animation.type", :items="animations", :i18n="true")
                om-background-box(
                  v-if="!isTeaser && (isInCenter || (isWithOverlayOn && isFitToScreen && !mobilePreview) || (!isWithoutOverlayOnMobile && mobilePreview)) && !isNano && !isEmbedded"
                  :isOpen="true"
                  label="websiteOverlay"
                  propsProperty="globalStyle.overlay"
                  subPath="globalStyle.overlay"
                  :canRemainOpen="true"
                  :leftSidebar="true"
                  ref="websiteOverlay"
                  data-track-property="component:website-overlay"
                )
                om-background-box(
                  v-if="isEmbedded && !isTeaser && !isNano"
                  :isOpen="true"
                  label="containerBackground"
                  propsProperty="globalStyle.overlay"
                  subPath="globalStyle.overlay"
                  :canRemainOpen="true"
                  :leftSidebar="true"
                  ref="websiteOverlay"
                  data-track-property="component:website-overlay"
                )
          sidebar-tab-content.sidebar-tab-content-scrollable-div(id="layout" data-track-property="location:layout")
            PageStructure-V2(v-if="hasSelectedPage")
          sidebar-tab-content(id="elements" data-track-property="location:elements")
            .section.section-scrollable
              .section-content
                om-info-box#AddElement.mb-3.mr-3(
                  v-if="showAddElementInfoBox"
                  :title="$t('infoBox.addElementTitle')"
                  :text="$t('infoBox.addElementText')"
                  :fontSize="14"
                )
                .user-info-message.alert.alert-danger(v-if="userInfoMessage" style="width:100%;") {{$t(userInfoMessage)}}
                .element-type-group.w-100(v-for="kind in elementKinds")
                  .element-type-group-title {{ $t(`sidebarSettings.elements.group.title.${kind}`) }}
                  .element-type-group-items.d-flex-1.flex-wrap(:data-track-property="getElementGroupDataTrackProperty(kind)")
                    template(v-for="type in elementTypes[kind]")
                      .element-box.element-box-disabled(v-if="['Feedback', 'Survey'].includes(type) && hasFeedbackOnPage"
                                draggable="false"
                                v-tooltip="{content: $t('oneFeedbackPerPage')}")
                        om-unique-icon.vector-smart-object(:color="getColorByType(type)" :name="getTypeSvg(type)" :size="'100%'" draggable="false" ondragstart="return false")
                        .pdt-05.element-box-name(style="text-align: center") {{$tc(getTypeName(type), 1)}}
                      .position-relative(v-else)
                        .element-box-wrapper(
                          draggable="true"
                          :title="$t('dragToEdit')"
                          @mousedown="elementMouseDown"
                          @dragstart="dragstart($event, type)"
                          @dragend="dragend"
                          @click="handleElementClick(type)"
                          :data-track-property="getElementDataTrackProperty(type)"
                        )
                          .element-box
                            om-unique-icon.vector-smart-object(:color="getColorByType(type)" :name="getTypeSvg(type)" :size="'100%'" draggable="false" ondragstart="return false")
                            .pdt-05.element-box-name(style="text-align: center") {{$tc(getTypeName(type), 1)}}
</template>
<script>
  import { createNamespacedHelpers, mapGetters, mapMutations, mapState } from 'vuex';
  import { get as _get } from 'lodash-es';
  import { isSameThemekit } from '@om/themekit';
  import { getNameFromURL } from '@om/template-properties/src/imageReplace';
  import Badge from '@/components/Badge';
  import CustomThemeNameSelect from '@/editor/components/sidebar/CustomThemeNameSelect.vue';
  import PageStructureV2 from '@/editor/components/sidebar/PageStructureV2.vue';
  import featureForFeedbackMixin from '@/editor/mixins/featureForFeedback';
  import itemMixin from '@/editor/mixins/item';
  import { TEMPLATE_FEATURES } from '@/editor/util';
  import * as SideBarSvg from '@/assets/editor/svg/sidebar';
  import embeddedV3 from '@/mixins/embeddedV3';
  import { dragAndDropTracker } from '@/services/userInteractionTracker/tracker';
  import { isCustomCssJsEditorDisabled } from '@/utils/features';
  import { UilQuestionCircle } from '@iconscout/vue-unicons';
  import DeviceSelector from '@/editor/components/sidebar/components/DeviceSelector.vue';
  import elementHandlerMixin from '@/editor/mixins/elementHandler';
  import logoMixin from '@/mixins/logo';

  const { mapState: mapProductTourState, mapActions: mapProductTourActions } =
    createNamespacedHelpers('productTour');

  const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

  const buttons = [
    {
      components: 'Theme',
      text: 'editor.customTheme',
      isActive: false,
      id: 'theme',
      isHovered: false,
      shouldDisplay: () => {
        return window.om.store.getters.isUniversal;
      },
      trackProperty: 'customTheme',
    },
    {
      components: 'Display',
      text: 'editor.themeAndDisplay',
      isActive: false,
      id: 'step',
      isHovered: false,
      shouldDisplay: () => {
        return true;
      },
      trackProperty: 'display',
    },
    {
      components: 'AddElement',
      text: 'editor.addElement',
      isActive: false,
      id: 'elements',
      isHovered: false,
      shouldDisplay: () => {
        return true;
      },
      trackProperty: 'elements',
    },
    {
      components: 'DevMode',
      text: 'editor.devMode',
      isActive: false,
      id: 'dev',
      isHovered: false,
      shouldDisplay: () => {
        return true;
      },
      trackProperty: 'devMode',
    },
  ];
  const PURPLE = '#936EC8';
  const RED = '#DE6D93';
  const BLACK = '#505763';
  const BLUE = '#6D9AE7';
  const GREEN = '#58A6A4';
  const COLORS_BY_TYPE = {
    Row: BLACK,
    CustomHTML: BLACK,
    Image: BLUE,
    FloatingImage: BLUE,
    Spacer: BLUE,
    Divider: BLUE,
    Video: BLUE,
    Text: RED,
    Countdown: GREEN,
    Coupon: GREEN,
    Product: GREEN,
    Social: GREEN,
    Button: GREEN,
  };

  export default {
    components: {
      ...SideBarSvg,
      PageStructureV2,
      PositionSvg: () => import('@/editor/components/svg/PositionSvg.vue'),
      PositionSvgMobile: () => import('@/editor/components/svg/PositionSvgMobile.vue'),
      UilQuestionCircle,
      Badge,
      CustomThemeNameSelect,
      DeviceSelector,
    },
    mixins: [itemMixin, featureForFeedbackMixin, embeddedV3, elementHandlerMixin, logoMixin],
    props: ['saveType', 'isClosed'],

    data() {
      return {
        stylesTabActive: false,
        layoutTypes: ['Single', 'Double', 'Triple'],
        level: 2,
        resetTimeout: null,
        TEMPLATE_FEATURES,
        editor: {
          transition: null,
        },
        buttons,
        oldTypes: [
          { key: 'none', value: 'no-animation' },
          { key: 'bounce', value: 'bounce' },
          { key: 'fadeIn', value: 'fadeIn' },
          { key: 'slideInLeft', value: 'slideInLeft' },
          { key: 'slideInTop', value: 'slideInUp' },
          { key: 'fall', value: 'shake' },
          { key: 'stickyUp', value: 'slideInDown' },
          { key: 'flipHorizontal', value: 'flipInY' },
          { key: 'flipVertical', value: 'flipInX' },
          { key: 'sign', value: 'wobble' },
          { key: 'slit', value: 'tada' },
          { key: 'rotateLeft', value: 'rotateInDownLeft' },
          { key: 'rotateTop', value: 'rotateInUpLeft' },
          { key: 'superScaled', value: 'zoomIn' },
        ],
        overflowOptions: [
          { key: 'default', value: 'default' },
          { key: 'hidden', value: 'hidden' },
        ],
        alignItems: [
          { key: 'top', value: 'flex-start' },
          { key: 'center', value: 'center' },
          { key: 'bottom', value: 'flex-end' },
        ],
        horizontalAlignment: [
          { key: 'left', value: 'flex-start' },
          { key: 'center', value: 'center' },
          { key: 'right', value: 'flex-end' },
        ],
        currentOffset: null,
        slideInProgress: false,
        dragEndDropTracker: null,
        logo: null,
      };
    },

    computed: {
      ...mapGetters([
        'isThemeKit',
        'isSidebar',
        'isNano',
        'isTemplateEditorMode',
        'isEmbedded',
        'databaseId',
        'hasTemplateFeature',
        'teaserPage',
        'isActiveShopifyDomain',
        'hasAccountFeature',
        'isUniversal',
        'accountFeatures',
        'hasActiveShopify',
        'isFullscreen',
      ]),
      ...paymentGetters(['isFreePackage']),
      ...mapState([
        'account',
        'template',
        'selectedElement',
        'selectedPage',
        'globalStyle',
        'paneLevel',
        'pages',
        'paneL2',
        'paneL3',
        'userInfoMessage',
        'hasFeedbackOnPage',
        'hasWheel',
        'mobilePreview',
        'campaign',
        'showCustomEditorBox',
        'customJsByEvents',
        'customCss',
        'themeKit',
        'elementStylesChanged',
        'themekitChanged',
        'isThemekitHidden',
      ]),
      ...mapProductTourState(['driver']),
      usedImage() {
        return (
          this.images?.find(this.imageNameComparer) ??
          this.allTemplateImages?.find(this.imageNameComparer) ??
          null
        );
      },
      hasLogoDefinedInBaseTheme() {
        return !!this.themeKit?.logo?.original ?? false;
      },
      currentLogo() {
        return this.usedImage?.url;
      },
      isOriginalLogo() {
        return this.logo === this.themeKit?.logo?.original;
      },
      isCustomThemeChanged() {
        return (
          !this.isTemplateEditorMode &&
          (!this.isThemekitHidden || this.elementStylesChanged || this.themekitChanged)
        );
      },
      hasSelectedPage() {
        return this.selectedPage?.uid !== undefined;
      },
      isInCenter() {
        return this.globalStyle.overlay.position === 5;
      },
      isTeaser() {
        if (this.selectedPage && this.selectedPage.isTeaser) return true;
        if (this.paneL2) {
          const type = this.paneL2.replace('Pane', '');
          return type === 'Teaser';
        }
        return false;
      },
      showFitToScreen() {
        return this.isSidebar && !this.isNano && !this.hasWheel;
      },
      showThemeInfoBox() {
        return !_get(this.account, 'profile.infoBox.hideTheme');
      },
      showAddElementInfoBox() {
        return !_get(this.account, 'profile.infoBox.hideAddElement');
      },
      isWithoutOverlayOnMobile() {
        return this.getValueOf('globalStyle.overlay.withoutOverlayOnMobile');
      },
      isWithOverlayOn() {
        return this.globalStyle.overlay.withOverlay;
      },
      isFitToScreen() {
        return this.getValueOf('globalStyle.overlay.fitToScreen');
      },
      isFitToScreenOnMobile() {
        return this.getValueOf('globalStyle.overlay.fitToScreenOnMobile');
      },
      isV2Teaser() {
        return (
          this.hasTemplateFeature(this.TEMPLATE_FEATURES.NEW_TEASER) &&
          this.selectedPage &&
          this.selectedPage.isTeaser
        );
      },
      overlayAnimation() {
        if (this.template) return this.template.style.overlay.animation;
        return null;
      },
      paneL2Key() {
        if (this.paneL2 === 'RowPane' && this.selectedRow) {
          return this.selectedRow.uid;
        }

        if (this.selectedElement) {
          return this.selectedElement.uid;
        }

        return this.paneL2;
      },
      position() {
        return `position-${this.paneLevel}`;
      },
      elementTypes() {
        const result = {
          basic: ['Text', 'Image', 'FloatingImage', 'Button'],
          form: ['Input', 'Radio', 'Checkbox', 'Dropdown', 'Feedback', 'Textarea', 'Survey'],
          structure: ['Row', 'Spacer', 'Divider'],
          product: ['Countdown', 'Coupon', 'Product'],
          other: ['Social', 'Video', 'CustomHTML'],
        };

        if (this.isV2Teaser) {
          return { basic: ['Text', 'Image', 'FloatingImage'] };
        }

        if (this.saveType === 'base' && this.databaseId === 44) {
          result.other.push('ScratchCard');
          result.other.push('PickAPresent');
        }

        if (this.saveType === 'base' && this.databaseId === 44 && !this.isFullscreen) {
          result.other.push('LuckyWheel');
        }

        if (this.isNano) {
          result.other.splice(result.other.indexOf('Video'), 1);
        } else if (this.isEmbeddedV3) {
          result.other.splice(result.other.indexOf('Video'), 1);
        }

        return result;
      },
      options() {
        const result = [];

        if (!this.isEmbedded) {
          result.push(
            { label: 'generalSettings', pane: 'OverlayPane' },
            { label: 'tab', pane: 'TeaserPane' },
          );
        }

        result.push({ label: 'fontManager.title', pane: 'FontManagerPane' });

        if (this.isTemplateEditorMode && this.databaseId === 44) {
          result.push({ label: 'ribbon', pane: 'RibbonPane' });
        }
        return result;
      },
      elementKinds() {
        if (this.isV2Teaser) {
          return ['basic'];
        }
        const kindOf = ['basic', 'form', 'product', 'other'];

        if (!this.isNano) {
          kindOf.splice(2, 0, 'structure');
        }

        return kindOf;
      },
      globalPosition() {
        return this.$store.state.globalStyle.overlay.position;
      },
      isLeftPosition() {
        return [1, 4, 7].includes(this.globalPosition);
      },
      isRightPosition() {
        return [3, 6, 9].includes(this.globalPosition);
      },
      isTopPosition() {
        return [1, 2, 3].includes(this.globalPosition);
      },
      isBottomPosition() {
        return [7, 8, 9].includes(this.globalPosition);
      },
      isHorizontalMiddlePosition() {
        return this.globalPosition === 5;
      },
      isVerticalMiddlePosition() {
        return [4, 6].includes(this.globalPosition);
      },
      animationType() {
        if (this.$store.state.globalStyle.animation)
          return this.$store.state.globalStyle.animation.type;
        return null;
      },
      animationTypes() {
        let types = [];

        const shared = [
          { key: 'none', value: 'no-animation' },
          { key: 'zoom', value: 'zoomIn' },
          { key: 'fade', value: 'fadeIn' },
        ];

        const sidebar = [];

        if (!this.isHorizontalMiddlePosition) {
          sidebar.push({
            key: 'fadeHorizontal',
            value: this.isLeftPosition ? 'fadeInLeft' : 'fadeInRight',
          });
        }

        if (!this.isVerticalMiddlePosition) {
          sidebar.push({
            key: 'fadeVertical',
            value: this.isTopPosition ? 'fadeInDown' : 'fadeInUp',
          });
        }

        const nanobar = [
          { key: 'none', value: 'no-animation' },
          { key: 'slide', value: this.isTopPosition ? 'slideInDown' : 'slideInUp' },
          { key: 'fade', value: 'fadeIn' },
        ];

        if (!this.isNano) {
          types = [].concat(types, shared);
        } else {
          types = [].concat(types, nanobar);
        }

        if (this.isSidebar && !this.isNano) {
          types = [].concat(types, sidebar);
        }
        return types;
      },
      animations() {
        if (this.usingDeprecatedAnimation && this.animationType) {
          const oldType = this.oldTypes.find((o) => o.value === this.animationType);
          return [...this.animationTypes, { key: oldType.key, value: oldType.value }];
        }
        return this.animationTypes;
      },
      usingDeprecatedAnimation() {
        const animationTypes = this.animationTypes.map((a) => a.value);
        return !animationTypes.includes(this.animationType);
      },
      getLocale() {
        return this.$i18n.locale;
      },
      activeTab() {
        return this.buttons.find((button) => button.isActive);
      },
    },
    watch: {
      themeKit: {
        handler(v) {
          if (!v || !this.savedThemeKit || !this.savedThemeKit.colors) return;
          this.setThemekitChanged(!isSameThemekit(v, this.savedThemeKit));
        },
        deep: true,
      },
      paneLevel(newLevel, oldLevel) {
        if (newLevel < oldLevel) {
          clearTimeout(this.resetTimeout);

          this.resetTimeout = setTimeout(
            function () {
              // make sure closed panes are not kept alive in the background
              if (newLevel < 3) {
                this.$store.state.paneL3 = null;
              }
              if (newLevel < 2) {
                this.$store.state.paneL2 = null;
              }
            }.bind(this),
            400,
          ); // transition duration
        }
      },
      isClosed(newVal) {
        if (newVal) {
          this.closeSideBar();
        }
      },
      isTeaser(newVal) {
        if (newVal) {
          this.buttons = this.buttons.filter(
            (button) => button.id !== 'layout' && button.id !== 'elements',
          );
        } else {
          this.buttons = buttons;
        }
      },
    },

    mounted() {
      this.logo = this.themeKit?.logo?.current ?? null;
      window.om.bus.$on('show-left-sidebar-content', (properties) => {
        if (typeof properties === 'object' && properties) {
          const { buttonIndex, accordion, withoutCloseCase, closeOthers } = properties;
          this.selectButton(buttonIndex, withoutCloseCase);
          setTimeout(() => {
            if (!this.$refs[accordion]) return;

            this.$refs[accordion].open();
          }, 300);

          if (closeOthers) {
            ['themeColors', 'positions', 'transition', 'websiteOverlay'].forEach((accordionRef) => {
              setTimeout(() => {
                if (accordionRef !== accordion && this.$refs[accordionRef]) {
                  this.$refs[accordionRef].close();
                }
              }, 300);
            });
          }
        } else {
          this.selectButton(properties);
        }
      });

      window.om.bus.$on('closeSideBar', () => {
        this.closeSideBar();
        this.deselectButton();
      });

      window.parent.om.bus.$on('deselectAllButton', () => {
        const devButton = this.buttons.find((button) => button.id === 'dev');
        if (devButton.isActive) this.deselectButton();
      });

      window.om.bus.$on('addImages', this.refetchImages);
    },

    beforeDestroy() {
      this.$bus.$off('show-left-sidebar-content');
      this.$bus.$off('deselectAllButton');
      this.$bus.$off('closeSideBar');
      this.$bus.$off('addImages');
    },
    methods: {
      ...mapMutations([
        'activatePaneL2',
        'updateDataProperty',
        'setStateAttr',
        'deselectAll',
        'setThemekitChanged',
        'showImageManager',
        'setImageManagerLogoUpload',
      ]),
      ...mapProductTourActions(['next']),
      showHelp() {
        const helpUrl = 'https://support.optimonk.com/hc/en-us/articles/4407887326610-Editor';
        window.open(helpUrl, '_blank');
      },
      setHoverClass(index) {
        this.buttons[index].isHovered = true;
      },
      removeHoverClass(index) {
        this.buttons[index].isHovered = false;
      },
      selectButton(index, withoutCloseCase = false) {
        if (this.buttons[index].isActive && this.buttons[index].id !== 'dev') {
          if (withoutCloseCase) return;
          this.closeSideBar();
          this.deselectButton();
          return;
        }
        if (this.buttons[index].id !== 'dev' && this.showCustomEditorBox) {
          this.setCustomEditorTrigger(false);
        }
        if (this.isClosed) this.openSideBar();
        this.deselectButton();
        this.buttons[index].isActive = !this.buttons[index].isActive;
        if (this.$refs.sidebarTabWrapper) {
          this.$refs.sidebarTabWrapper.$emit('show-content', this.buttons[index].id);
        }
        if (this.buttons[index].id === 'dev') {
          this.handleDevModeClick();
        }
      },

      shouldShowFeatureForFeedback() {
        const isDevModeDisabled = isCustomCssJsEditorDisabled(this.accountFeatures);
        return (
          isDevModeDisabled &&
          this.hasIntercom &&
          this.hasActiveShopify &&
          this.isFreePackage &&
          !this.isTrialShop
        );
      },

      handleDevModeClick() {
        if (this.shouldShowFeatureForFeedback()) {
          this.$modal.show('feature-for-feedback', {
            feature: 'devMode',
          });
          this.closeSideBar();
          this.deselectButton();
        } else {
          this.setCustomEditorTrigger(true);
          this.closeSideBar();
        }
      },
      selectAccordion() {
        this.$bus.$emit('toggleWebsiteOverlay');
      },
      deselectButton() {
        this.buttons.forEach((button) => {
          button.isActive = false;
        });
      },
      closeSideBar() {
        this.$emit('update:isClosed', true);
      },
      openSideBar() {
        this.$emit('update:isClosed', false);
      },
      setCustomEditorTrigger(value) {
        this.setStateAttr({ attr: 'showCustomEditorBox', value });
      },
      getSidebarTrackProperty(index) {
        const component = this.buttons[index].trackProperty;
        let setting = 'open';
        let changeProperty = `left.menu.${component}.open`;
        if (this.buttons[index].isActive) {
          setting = 'close';
          changeProperty = `left.menu.${component}.close`;
        }
        return `component:${component}|setting:${setting}|change:${changeProperty}`;
      },
      getElementGroupDataTrackProperty(kind) {
        return `group:${kind}`;
      },
      getElementDataTrackProperty(type) {
        return `component:Om${type}|change:{group}.elements.Om${type}.{event}`;
      },
      dragstart(e, data) {
        const crt = e.currentTarget.cloneNode(true);
        crt.style.position = 'absolute';
        crt.style.transform = 'translateX(-500px)';
        crt.querySelector('.element-box').style.transform = 'rotate(10deg)';
        document.querySelector('.element-type-group-items').appendChild(crt);
        e.dataTransfer.setDragImage(crt, 30, 30);

        const type = data === 'Row' ? 'row' : 'element';

        e.dataTransfer.setData('Text', data);

        const tracker = dragAndDropTracker.create();
        tracker.start(`Om${data}`, e.target);
        tracker.setDrag();
        this.dragEndDropTracker = tracker;

        window.om.bus.$emit('setDragInfo', {
          type,
          data: data === 'Row' ? 2 : data,
          tracker,
          event: 'click',
          drag: true,
        });
        this.deselectAll();
      },
      dragend() {
        this.dragEndDropTracker.end();
      },
      getTypeImage(type) {
        return type === 'Row' ? 'Double' : type;
      },
      getTypeName(type) {
        return type === 'Row' ? 'block' : type.toLowerCase();
      },
      getTypeTransform(type) {
        return type.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
      },
      getTypeSvg(type) {
        if (type === 'Row') return 'block';
        if (type === 'CustomHTML') return 'html';
        return this.getTypeTransform(type);
      },
      getColorByType(type) {
        return COLORS_BY_TYPE[type] || PURPLE;
      },
      elementMouseDown() {
        if (
          this.driver &&
          this.driver.hasHighlightedElement() &&
          this.driver.getHighlightedElement().node === document.querySelector('.fake-left-sidebar')
        ) {
          this.next();
          window.om.bus.$emit('showProductTourSuccess');
        }
      },
      desktopStyle() {
        if (!this.mobilePreview) {
          return {
            width: '55%',
            height: '100%',
          };
        }
        return null;
      },
      clearLogo() {
        const previous = this.themeKit.logo.current;
        if (this.themeKit?.logo) {
          this.themeKit.logo.current = null;
        }
        this.$store.dispatch('handleLogoDelete', {
          imageName: previous,
        });
      },
      uploadLogo() {
        this.setImageManagerLogoUpload(true);
        this.showImageManager(true);
      },
      imageNameComparer({ url }) {
        const pureName = getNameFromURL(url);
        const pureLogo = getNameFromURL(this.themeKit?.logo?.current);

        return pureName && pureLogo && pureName === pureLogo;
      },
      refetchImages() {
        this.$apollo?.queries?.images?.refetch?.();
      },
    },
  };
</script>

<style lang="sass">
  .brand-kit
    &:not(.custom-theme)
      margin-top: 16px
      .brand-heading
        &:not(:first-child)
          margin-top: 32px
        &:last-child
          position: absolute
          margin-top: 62px
    .alt-title
      color: #23262A
      font-size: 0.8125rem
      font-weight: 500
      margin-bottom: 12px
  .left-sidebar
    .help-icon
      position: absolute
      bottom: 0
      padding: 0.75rem 1.5rem
      display: flex
      flex-direction: column
      &:lang(hu)
        padding: 0.75rem 0.7rem
      .text
        font-size: 12px
        line-height: 20px
        font-weight: 500
        color: #505763
        margin-top: 6px
        display: flex
      svg
        height: 1.5rem
        width: 1.5rem
        color: #505763
        display: flex
        align-self: center
  .h3-elem
    font-size: 14px !important
    font-weight: 500 !important
  .sidebar
    height: calc(100% - 48px) !important
    .section
      max-height: 100% !important
  .section-content
    padding: 0rem !important
  .sidebar-button
    height: 84px
    &:hover > .small-font-size
      color: #8F97A4
  .inner-div
    display: flex
    justify-content: center
    align-items: center
  .angle
    &-close,
    &-open
      &:after
        color: black
  .pointer
    cursor: pointer
  .custom-width
    width: 72px
    background: white
    position: relative
    z-index: 4
  .small-font-size
    font-family: Roboto
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 12px
    text-align: center
    color: #505763
  .active
    border-radius: 4px
    color: #ED5A29
  .inner-div
    width: 48px
    height: 48px
    margin-bottom: 5px
  .left-button-selected
    background: #FFEFE5
    border-radius: 8px
    animation: custom-animation 300ms ease-in
  .not-selected
    animation: custom-animation-reverse 300ms
  .inherit
    position: inherit !important
  .element-box
    height: 72px !important
    width: 72px !important
    &-name
      font-size: 11px !important
      color: #505763
      font-weight: 400
      line-height: 1 !important
  @keyframes custom-animation
    from
      width: 0
      height: 48px
      background-color: transparent
    to
      width: 48px
      height: 48px
      background-color: #FFEFE5

  @keyframes custom-animation-reverse
    from
      width: 48px
      height: 48px
      background-color: #FFEFE5
    to
      width: 0
      height: 48px
      background-color: transparent
  .new-svg
    position: absolute
    right: 10px
    top: 23px
    &-to-element
      cursor: default
      position: absolute
      top: 10px
      right: 10px
  .popper ::v-deep .popper__arrow
    top: 6px !important

  #brandkit-logo
    gap: 8px
    .btn
      height: max-content
  .brandkit-logo-preview-box
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMElEQVQ4T2N89uzZfwY8QFJSEp80A+OoAcMiDP7//483HTx//hx/Ohg1gIFx6IcBALl+VXknOCvFAAAAAElFTkSuQmCC")
    max-width: 15rem
    height: 3.75rem
    border: 1px solid #ececec
    border-radius: 4px
    padding: .25rem
    img
      width: 100%
      height: auto
      max-height: 3.25rem
      object-fit: contain
</style>
