export default {
  data: () => ({ popoverVisible: false, popoverComponentName: null, top: 0, left: 0 }),
  methods: {
    showPopover({ selector, name }) {
      this.top = document.querySelector(selector).getBoundingClientRect().top + window.scrollY;
      this.left = 350;
      this.popoverComponentName = name;
      this.popoverVisible = true;
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
        this.toggleOutsideClickHandler(true);
      }, 50);
    },
    closePopover() {
      this.popoverVisible = false;
      this.$bus.$emit('sidebarPopoverClosed');
      this.toggleOutsideClickHandler(false);
    },
    toggleOutsideClickHandler(value) {
      const elements = [
        document.body,
        document.querySelector('#workspaceFrame').contentWindow.document.body,
      ];
      elements.forEach((element) => {
        if (value) {
          element.addEventListener('click', this.outsideClickHandler);
        } else {
          element.removeEventListener('click', this.outsideClickHandler);
        }
      });
    },
    outsideClickHandler(e) {
      if (!this.hasSomeParentTheClass(e.target, 'popover-content')) {
        this.closePopover();
      }
    },
    hasSomeParentTheClass(element, className) {
      if (element.classList.contains(className)) return true;
      return element.parentElement && this.hasSomeParentTheClass(element.parentElement, className);
    },
  },
};
