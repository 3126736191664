import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink, concat } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';
import { graphqlUrl } from '../config/url';

const GQL_API = graphqlUrl;
const httpLink = new HttpLink({ uri: GQL_API, credentials: 'include' });
const dockerLink = new HttpLink({ uri: process.EXPORT_API || GQL_API });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'x-om-token': localStorage.getItem('omEditorToken'),
    },
  });

  return forward(operation);
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
  defaultOptions,
});

const dockerClient = new ApolloClient({
  link: concat(authMiddleware, dockerLink),
  cache: new InMemoryCache(),
  defaultOptions,
});
export { client, dockerClient, gql };
