<template lang="pug">
svg(width='21', height='9', viewBox='0 0 21 9', fill='none', xmlns='http://www.w3.org/2000/svg')
  path(d='M0 3C0 1.34315 1.34315 0 3 0H18C19.6569 0 21 1.34315 21 3V9H0V3Z', :fill='color')
  path(d='M11.0962 3.91038H13V4.95755H11.0962V7H9.91383V4.95755H8V3.91038H9.91383V2H11.0962V3.91038Z', :fill="crossColor")
</template>

<script>
  export default {
    props: {
      color: {
        type: String,
      },
      crossColor: {
        type: String,
        default: 'white',
      },
    },
  };
</script>
