<template lang="pug">
.form-modal(
  v-if="showFormManager.deleteFields"
  @mousedown="changeFormManagerVisibility({ hide: 'deleteFields' })"
)
  .content(style="min-height: 15rem; width: 30rem" @mousedown.stop="")
    .header.om-bottom-divider.pb-4
      .header-inner.w-100
        .om-col.om-col-12.header-content
          .d-flex.align-items-center
            .mb-0.font-weight-bold(style="font-size: 18px") {{ $t('deleteFieldModal.title') }} {{ inputName }}
            .button.om-close.ml-auto(style="color: #b9bec6" @click="goBack()") &times;
    .om-col.om-col-12.header-content.ml-3.mt-3.delete-field-content
      span {{ $t('deleteFieldModal.text') }}
    .om-row.footer.mt-3
      .om-col.om-col-2
        .button.button-link(@click="goBack()") {{ $t('cancel') }}
      .om-col.om-col-3
        .button.button-primary.button-large.custom(@click="deleteField()") {{ $t('delete') }}
</template>
<script>
  import { mapState, mapMutations } from 'vuex';

  export default {
    data() {
      return {
        inputNameVal: null,
      };
    },

    computed: {
      ...mapState(['showFormManager', 'inputs', 'selectedElementValue', 'templateSaveData']),
      inputName: {
        get() {
          return this.inputNameVal === null ? this.selectedElementValue.name : this.inputNameVal;
        },
      },
    },

    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      deleteField() {
        this.$bus.$emit('deleteField', this.selectedElementValue);
        this.changeFormManagerVisibility({ hide: 'deleteFields' });
      },
      goBack() {
        this.changeFormManagerVisibility({ hide: 'deleteFields' });
        this.changeFormManagerVisibility({ show: 'manageField' });
      },
    },
  };
</script>
<style lang="sass" scoped>
  .delete-field-content
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 16px
    color: #8F97A4
  .button-link
    color: black !important
  .custom
    font-family: Roboto
    font-style: normal
    font-weight: bold
</style>
