<template lang="pug">
.form-modal(
  v-if="showFormManager.inputValidations"
  @mousedown="changeFormManagerVisibility({ hide: 'inputValidations' })"
)
  .content(style="min-height: fit-content" @mousedown.stop="")
    .header.om-bottom-divider.p-3
      .header-inner
        .title
          .font-weight-bold.d-flex.align-items-center(style="font-size: 18px") {{ $t('inputValidationsModal.title') }}
        .close
          om-button.ml-auto.p-1(
            icon="times"
            iconSize="1.715em"
            ghost
            iconOnly
            style="color: #b9bec6"
            @click="goBack()"
          )
    .body-content
      .d-flex.justify-content-center.align-items-center
        .row-holder.mb-2
          label.allow-block-description {{ $t('inputValidationsModal.description') }}
          .allow-block-container.mb-6.d-flex
            .each-radio
              input#allow(
                type="radio"
                :value="true"
                v-model="selectedElement.data.form.customSettings.allowBlock"
                @change="setSelectedType(true)"
              )
              label.mb-0(for="allow") {{ $t('inputValidationsModal.allowToggle') }}
            .each-radio.ml-4
              input#block(
                type="radio"
                :value="false"
                v-model="selectedElement.data.form.customSettings.allowBlock"
                @change="setSelectedType(false)"
              )
              label.mb-0(for="block") {{ $t('inputValidationsModal.blockToggle') }}
          .email-addresses
            template(v-if="allowBlock")
              label.text.mb-0 {{ $t('inputValidationsModal.allowSubtitle') }}
              textarea(
                v-model="selectedElement.data.form.customSettings.allowList"
                maxlength="1000"
                placeholder="optimonk.com\n*@optimonk.com"
              )
            template(v-else)
              label.text.mb-0 {{ $t('inputValidationsModal.blockSubtitle') }}
              textarea(
                v-model="selectedElement.data.form.customSettings.blockList"
                maxlength="1000"
                placeholder="optimonk.com\n*@optimonk.com"
              )
            .small-alt-subtitle {{ $t('inputValidationsModal.hint') }}
          .custom-error-message
            label.text.mb-0 {{ $t('errorMessage') }}
            om-simple-input.mb-2(
              property="selectedElement.data.form.customSettings.allowBlockCustomErrorMessage"
            )
    .footer
      om-button(primary @click="goBack()") {{ $t('save') }}
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import itemMixin from '@/editor/mixins/item';

  export default {
    components: {
      Dropdown: () => import('@/editor/components/sidebar/components/Dropdown.vue'),
      OmTextarea: () => import('@/editor/components/elements/OmTextarea.vue'),
    },
    mixins: [itemMixin],
    data() {
      return {
        allowBlockItems: [
          { key: 'inputValidationsModal.allowToggle', value: true },
          { key: 'inputValidationsModal.blockToggle', value: false },
        ],
      };
    },
    computed: {
      ...mapState(['showFormManager', 'selectedElement']),
      allowList() {
        return this.selectedElement.data.form.customSettings?.allowList ?? '';
      },
      blockList() {
        return this.selectedElement.data.form.customSettings?.blockList ?? '';
      },
      allowBlock() {
        return this.selectedElement.data.form.customSettings?.allowBlock;
      },
    },
    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      setSelectedType(value) {
        this.setValueOf('selectedElement.data.form.customSettings.allowBlock', value);
      },
      goBack() {
        this.selectedElement.data.form.customSettings.allowList = this.normalizeRules(
          this.allowList,
        );
        this.selectedElement.data.form.customSettings.blockList = this.normalizeRules(
          this.blockList,
        );
        this.changeFormManagerVisibility({ hide: 'inputValidations' });
      },
      normalizeRules(list) {
        return list
          .split('\n')
          .map((rule) => {
            if (rule.charAt(0) === '/' && rule.lastIndexOf('/') > -1) {
              return rule.substring(1, rule.lastIndexOf('/'));
            }
            return rule.trim();
          })
          .filter((rule) => rule)
          .join('\n');
      },
    },
  };
</script>
<style lang="sass" scoped>
  .text
    font-weight: 400
    font-size: 12px
    color: #505763
  .form-modal
    .content
      width: 26.25rem
      .header
        padding: unset !important
      .header-inner
        padding: 0
        display: flex
        width: 100%
        .title
          display: flex
          margin-left: 40px
          margin-top: 20px
          margin-bottom: 20px
        .close
          display: flex
          margin-left: auto
          margin-right: 25px
      .body-content
        margin-left: 40px
        margin-right: 40px
        margin-top: 24px
        .allow-block-description
          font-weight: 400
          font-size: 12px
          color: #8F97A4
          margin-bottom: 30px
        .custom-error-message
          margin-top: 20px
          ::v-deep.simpleInput
            background: white
            border: 1px solid #D5D8DD
            min-height: 40px
        .row-holder
          .each-radio
            display: flex
            min-width: 160px
            input[type=radio]
              align-self: center
              height: 20px
              width: 20%
            label
              font-size: 12px
              color: #505763
              align-self: center
      .footer
        min-height: 80px
        display: flex
        padding-right: 40px
    .custom-row-margin
      margin-left: 37.125px
      width: inherit
  .custom-input-validation-switch
    align-items: end !important
    margin-top: -6px
  .small-alt-title
    font-style: normal
    font-weight: 400
    font-size: 14px
    line-height: 20px
    color: #505763
  .small-alt-subtitle
    font-style: normal
    font-weight: 400
    font-size: 12px
    line-height: 20px
    color: #8f97a4
    max-width: 23rem
    text-align: justify
  .each-field
    font-family: Roboto
    font-style: normal
    font-weight: bold
    font-size: 14px
    line-height: 24px
    color: #505763
    &:last-child
      padding-bottom: 1rem !important
    &:first-child
      padding-top: 1rem !important
  .button-link
    color: black !important
  .custom
    font-family: Roboto
    font-style: normal
    font-weight: bold
  .row-holder
    width: 25.3rem
  textarea
    width: 100%
    height: 100px
    border: 1px solid #d7dbdf
    color: #505763
    font-size: 13px
    border-radius: 4px
    padding-left: 12px
    padding-top: 8px

  textarea::-webkit-input-placeholder
    color: #8f97a4
    font-size: 13px
  textarea:-moz-placeholder
    color: #8f97a4
    font-size: 13px
  textarea::-moz-placeholder
    color: #8f97a4
    font-size: 13px
  textarea::placeholder
    color: #8f97a4
    font-size: 13px
</style>
