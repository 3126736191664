import WebFontLoader from 'webfontloader';
import { getCdnUrl } from '@/config/cdn';

const getCustomFontUrl = (databaseId, font) => {
  return `${getCdnUrl()}/customFonts/${databaseId}/${font.family}/${font.family}.css`;
};

const mapFontToWebFontLoaderFormat = (font) => {
  let toLoad = font.family.replace(/ /g, '+');
  if (font.weights && font.weights.length) toLoad += `:${font.weights.join(',')}`;
  if (font.installedSubsets)
    toLoad += `${!font.weights || !font.weights.length ? ':' : ''}:${font.installedSubsets.join(
      ',',
    )}`;
  return toLoad;
};

const getUsedFonts = ({ installedFonts, usedFonts, databaseId }) => {
  const fonts = {
    families: [],
    customFamilies: [],
    customUrls: [],
  };

  installedFonts.forEach((font) => {
    if (usedFonts.includes(font.key)) {
      fonts.families.push(mapFontToWebFontLoaderFormat(font));
    }

    if (font.custom) {
      fonts.customFamilies.push(font.family);
      fonts.customUrls.push(getCustomFontUrl(databaseId, font));
    }
  });

  return fonts;
};

const getUnloadedFonts = ({ installedFonts, loadedFonts, databaseId }) => {
  const fonts = {
    families: [],
    customFamilies: [],
    customUrls: [],
  };

  installedFonts.forEach((font) => {
    if (!loadedFonts.includes(font.family)) {
      if (font.custom) {
        fonts.customFamilies.push(font.family);
        fonts.customUrls.push(getCustomFontUrl(databaseId, font));
      } else {
        fonts.families.push(mapFontToWebFontLoaderFormat(font));
      }
    }
  });

  return fonts;
};

const loadAdditionalFonts = ({ store }) => {
  const { loadedFonts } = store.state;
  const { installedFonts } = store.getters;
  const databaseId = store.state.databaseId || store.getters.databaseId;

  const { families, customFamilies, customUrls } = getUnloadedFonts({
    installedFonts,
    loadedFonts,
    databaseId,
  });

  if (!families.length && !customFamilies.length) {
    return;
  }

  families[families.length - 1] += '&display=swap';

  const loadedFontsSet = new Set(loadedFonts);

  const webFontLoaderConfig = {
    fontactive: (fontFamily) => {
      loadedFontsSet.add(fontFamily);
    },
    active: () => {
      store.commit('setStateAttr', { attr: 'loadedFonts', value: Array.from(loadedFontsSet) });
    },
    context: window,
    classes: false,
  };

  if (families.length) {
    let stylesheet = document.querySelector('#google-fonts-familiies');
    if (stylesheet) stylesheet.remove();
    stylesheet = document.createElement('link');
    stylesheet.id = 'google-fonts-familiies';
    stylesheet.rel = 'stylesheet';
    stylesheet.href = `https://fonts.googleapis.com/css?family=${families.join(
      encodeURIComponent('|'),
    )}`;
    document.head.appendChild(stylesheet);
  }

  if (customFamilies.length) {
    webFontLoaderConfig.custom = {
      families: customFamilies,
      urls: customUrls,
    };
  }

  WebFontLoader.load(webFontLoaderConfig);
};

const loadUsedFonts = ({ bus, store }) => {
  const { usedFonts, databaseId } = store.state;
  const { installedFonts } = store.getters;

  const { families, customFamilies, customUrls } = getUsedFonts({
    installedFonts,
    usedFonts,
    databaseId,
  });
  const loadedFonts = new Set();

  families[families.length - 1] += '&display=swap';

  let allFontsRendered = false;

  try {
    WebFontLoader.load({
      fontactive: (fontFamily) => {
        loadedFonts.add(fontFamily);
      },
      loading: () => {
        setTimeout(() => {
          if (!allFontsRendered && bus) bus.$emit('workspaceFontsLoaded');
        }, 5000);
      },
      active: () => {
        allFontsRendered = true;
        if (bus) bus.$emit('workspaceFontsLoaded');
        store.commit('setStateAttr', { attr: 'loadedFonts', value: Array.from(loadedFonts) });
        if (!store.state.docker) {
          loadAdditionalFonts({ store });
        }
      },
      google: {
        families,
      },
      custom: {
        families: customFamilies,
        urls: customUrls,
      },
      context: window,
      classes: false,
    });
  } catch (e) {
    console.error(e);
  }
};

export { loadUsedFonts, loadAdditionalFonts };
