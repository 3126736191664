import { mapGetters } from 'vuex';
import { ELEMENTS } from '@om/template-properties/src/propertyHelper';
import { dragAndDropTracker } from '@/services/userInteractionTracker/tracker';
import { inputElements } from '@om/editor-ssr-shared/src/utils';

export default {
  computed: {
    ...mapGetters(['isTemplateEditorMode']),
  },
  methods: {
    frameStore() {
      const workspace = document.getElementById('workspaceFrame');
      if (!workspace) {
        return;
      }
      return workspace.contentWindow.om?.store;
    },
    async handleElementClick(type) {
      const tracker = dragAndDropTracker.create();
      window.om.bus.$emit('setDragInfo', {
        type,
        data: type === 'Row' ? 2 : type,
        tracker,
        drag: false,
      });

      if (
        [
          ELEMENTS.OmInput,
          ELEMENTS.OmRadio,
          ELEMENTS.OmRadio,
          ELEMENTS.OmDropdown,
          ELEMENTS.OmFeedback,
          ELEMENTS.OmSurvey,
        ].includes(type)
      ) {
        window.parent.om.bus.$emit('changeFormManagerVisibility', { show: 'firstStep' });
        return;
      }

      let customField;
      const TYPE = `Om${type}`;
      if (inputElements.includes(TYPE)) {
        customField = await this.frameStore().dispatch('chooseInputField', {
          inputType: type.toLowerCase(),
        });
      }

      if (!this.isTemplateEditorMode && [ELEMENTS.OmSurvey, ELEMENTS.OmFeedback].includes(TYPE)) {
        await this.frameStore().dispatch('addElement');
        window.parent.om.bus.$emit('changeFormManagerVisibility', {
          show: 'missingCustomFields',
          meta: { type: TYPE },
        });
      } else if (type === 'Row') {
        this.frameStore().dispatch('addRow', {
          uid: null,
          pageId: this.selectedPage.uid,
          before: true,
          count: 1,
        });
      } else {
        await this.frameStore().dispatch('addElement', { customField });
      }
    },
  },
};
