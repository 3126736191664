<template lang="pug">
om-modal(name="page-rename"
         color="gray"
         :title="$t('pageRename')"
         @beforeOpen="beforeOpen"
         @opened="opened")
  template(slot="modal-body")
    input#titleInput(@keyup.enter="rename"
                        class="form-control form-control-lg"
                        :class="{'is-invalid': $v.title.$error}"
                        type="text"
                        :placeholder="$t('newNamePlaceholder')"
                        v-model.trim="title"
                        :aria-label="$t('newNamePlaceholder')")
  template(slot="modal-footer")
    .text-right
      button(@click="rename").btn.btn-secondary.px-3 {{ $t('apply') }}
</template>

<script>
  import { minLength, required } from 'vuelidate/lib/validators';
  import { mapGetters } from 'vuex';

  const _clone = (v) => JSON.parse(JSON.stringify(v));

  export default {
    data() {
      return {
        page: null,
        index: 0,
        title: '',
      };
    },

    validations: {
      title: {
        required,
        minLength: minLength(2),
      },
    },

    computed: {
      ...mapGetters(['pages']),
    },

    methods: {
      beforeOpen(event) {
        this.page = _clone(event.params.page);
        this.index = this.pages.findIndex((p) => p.uid === this.page.uid);
        this.title = this.page.data.title;
      },
      opened() {
        document.getElementById('titleInput').focus();
      },
      rename() {
        this.$v.$touch();

        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
          return;
        }

        this.page.data.title = this.title;

        this.$bus.$emit('updatePageTitle', {
          pageId: this.page.uid,
          title: this.title,
        });

        this.$emit('setPages');

        this.$modal.hide('page-rename');
      },
    },
  };
</script>
