const { omit, isEqual } = require('lodash');

const isSameElementStyles = (elementStyles, originElementStyles) => {
  if (elementStyles.length !== originElementStyles.length) return false;

  return elementStyles.every((elementStyle, index) => {
    const styles = omit(elementStyle, 'data.data.text');
    const ogStyles = omit(originElementStyles[index], 'data.data.text');

    return isEqual(styles, ogStyles);
  });
};

module.exports = isSameElementStyles;
