<template lang="pug">
popper(trigger="click" :options="{ placement: 'bottom' }" @created="onCreated" :disabled="disabled")
  .popper.datetime-popup
    v-date-picker(
      mode="single"
      :is-inline="true"
      :value="vCalendarToDate(startDate)"
      :available-dates="availableDates"
      @input="changeDate($event)"
      :formats="{ title: 'MMMM YYYY', weekdays: 'W', navMonths: 'MMM', input: [shortDateFormat], dayPopover: shortDateFormat }"
    )
    .d-flex.align-items-center.schedule-time-pick.px-2.py-3
      .d-flex.flex-column
        .d-flex.align-items-center.mb-2
          .flex-grow-1 {{ $t(`dateTime.${name}`) }}:
          vue-timepicker(v-model="startTime" :format="timeFormat" hide-clear-button)
    .d-flex.align-items-center.px-2.py-2
      .flex-grow-1
        .cursor-pointer(@click="cancel") {{ $t('cancel') }}
      button.brand-btn.brand-btn-primary.brand-btn-sm(@click="applyChange") {{ $t('apply') }}
  template(slot="reference")
    slot(name="reference")
</template>

<script>
  import moment from 'moment';
  import dateFormat from '@/mixins/dateFormat';

  const _clone = (v) => JSON.parse(JSON.stringify(v));
  export default {
    name: 'DateTimepicker',
    components: {
      VueTimepicker: () => import('vuejs-timepicker'),
    },
    mixins: [dateFormat],
    props: {
      disabled: {
        type: Boolean,
      },
      selectedDate: {
        type: Date,
      },
      selectedTime: {
        type: Object,
      },
      name: {
        type: String,
      },
      availableDates: {
        type: Object,
      },
    },

    data() {
      return {
        popoverInstance: null,
        startDate: null,
        startTime: { HH: '00', hh: '00', mm: '00', a: '' },
      };
    },

    methods: {
      onCreated($event) {
        this.popoverInstance = $event;
        this.startDate = _clone(this.selectedDate);
        this.startTime = _clone(this.selectedTime);
      },

      vCalendarToDate(field) {
        return field ? moment(field).toDate() : null;
      },

      changeDate(date) {
        if (!date) {
          this.startDate = this.selectedDate;
        } else {
          this.startDate = moment(date).toDate();
        }

        this.$emit('changeDate', { date, name: this.name });
      },

      applyChange() {
        this.$emit('applyChange', {
          selectedDate: moment(this.startDate).toDate(),
          selectedTime: this.startTime,
          name: this.name,
        });
        this.popoverInstance.doClose();
      },

      cancel() {
        this.startTime = this.selectedTime;
        this.startDate = this.selectedDate;
        this.$emit('cancel', { name: this.name });
        this.popoverInstance.doClose();
      },
    },
  };
</script>

<style lang="sass">
  .popover-content
    &-wrapper
      &.direction-bottom
        &.align-left
          &.interactive
            padding-top: 0 !important
    &.direction-bottom
      &.align-left
        background: #F4F7FA !important
        border-radius: 0px 0px 3px 3px !important
        &:after
          display: none !important
        .c-pane
          width: 14.8125rem !important
          min-width: auto !important
  .c-arrow-layout
    .svg-icon
      path
        fill: var(--brand-primary-color) !important
  .c-header
    .c-title-layout
      .c-title-popover
        .c-title-anchor
          .c-title
            font-weight: 500 !important
            font-size: .9375rem !important
  .c-weeks,
  .c-weekdays
    padding-left: 16px !important
    padding-right: 16px !important
  .c-weeks
    padding-bottom: 18px !important
  .c-day-content-wrapper
    .c-day-content
        border-radius: 3px !important
        font-size: 13px !important
        width: 1.75rem !important
        height: 1.75rem !important
        margin: 0 !important
        font-weight: 500 !important
  .c-day-backgrounds
    .c-day-background
      // TODO rgba brand color
      background-color: lighten(red, 30%) !important
    .c-day-layer
      &.c-day-scale-enter,
      &.c-day-scale-leave
        .c-day-background
          background-color: var(--brand-primary-color) !important
          border-color: var(--brand-primary-color) !important
          border-radius: 3px !important
      &.shift-left,
      &.shift-right
        .c-day-background
          background-color: var(--brand-primary-color) !important
          border-color: var(--brand-primary-color) !important
          border-radius: 3px !important
      &.c-day-slide-right-translate-enter,
      &.c-day-slide-left-translate-enter
        .c-day-background
          background-color: var(--brand-primary-color) !important
          border-color: var(--brand-primary-color) !important
          border-radius: 3px !important
</style>
